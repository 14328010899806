import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

interface ShoeGuideCtaBlockStyledProps {
  $backgroundColor?: string;
  $small?: boolean;
  $isTextDark?: boolean;
}

export const ShoeGuideCtaBlockStyled = styled.div<ShoeGuideCtaBlockStyledProps>`
  position: relative;
  height: 356px;
  width: 100%;
  z-index: 1;
  color: ${({ $isTextDark }) => ($isTextDark ? colors.black : colors.white)};

  button {
    height: ${spacing.medium};
    padding: 0 ${spacing.regular};
  }

  .Inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${spacing.large};
    height: 100%;
    background-color: ${({ $backgroundColor }) =>
      $backgroundColor ? $backgroundColor : colors.white};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding: 0;
      ${({ $small }) =>
        $small &&
        css`
          padding: 0 ${spacing.xsmall};
        `}
    }
  }

  h2 {
    color: ${({ $isTextDark }) => ($isTextDark ? colors.black : colors.white)};
    margin-top: 0;
    font-family: ${fontFamilies.fontHero};
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: ${spacing.regular};
    font-size: ${({ $small }) =>
      $small ? fontSizes.header4 : fontSizes.header3};
    line-height: ${({ $small }) =>
      $small ? lineHeights.header4 : lineHeights.header3};
    @media screen and (min-width: ${breakpoints.mobileMax}) {
      font-size: ${({ $small }) =>
        $small ? fontSizes.header4 : fontSizes.header2};
      line-height: ${({ $small }) =>
        $small ? lineHeights.header4 : lineHeights.header2};
    }
  }
`;
